import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { selectSalesYearFromRoute, selectSalesYearsChanging, selectSelectedSalesYear } from 'app/store/selectors';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { GlobalService } from '../../shared/services/global.service';
import { SnackbarService } from '../../shared/services/snackbar.service';
import * as globalActions from '../actions';

@Injectable()
export class SalesYearsEffects {
  constructor(
    private actions$: Actions,
    private globalService: GlobalService,
    private snackbarService: SnackbarService,
    private store: Store
  ) {}

  loadSalesYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalActions.loadSalesYears),
      switchMap(() => {
        return this.globalService.fetchSalesYears().pipe(
          map((salesYears) => globalActions.loadSalesYearsSuccess({ salesYears })),
          catchError((error) => {
            this.snackbarService.error('Error Fetching Sales Years');
            return of(globalActions.loadSalesYearsFail(error));
          })
        );
      })
    )
  );

  initializeSelectedSalesYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalActions.loadSalesYearsSuccess),
      concatLatestFrom(() => this.store.select(selectSalesYearFromRoute)),
      filter(([, salesYearFromRoute]) => salesYearFromRoute !== undefined),
      map(([, salesYearFromRoute]) => {
        return globalActions.changeSalesYear({ newSalesYear: salesYearFromRoute! });
      })
    )
  );

  handleNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      concatLatestFrom(() => [this.store.select(selectSalesYearFromRoute), this.store.select(selectSelectedSalesYear)]),
      filter(([, salesYearFromRoute, selectedSalesYear]) => salesYearFromRoute !== undefined && salesYearFromRoute !== selectedSalesYear),
      map(([, salesYearFromRoute]) => {
        return globalActions.changeSalesYear({ newSalesYear: salesYearFromRoute! });
      })
    )
  );

  handleNavigationEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [this.store.select(selectSalesYearsChanging)]),
      filter(([, salesYearChanging]) => salesYearChanging),
      map(() => {
        return globalActions.changeSalesYearSuccess();
      })
    )
  );
}
