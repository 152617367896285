import { PRIMARY_OUTLET, UrlTree } from '@angular/router';

export const getProperty = <T, K extends keyof T>(o: T, propertyName: K): T[K] => {
  // Note: o[propertyName] is of type T[K]
  return o[propertyName];
};

// determine if URL segment matches a specified path
export const getRouteURLSegment = (urlTree: UrlTree, path: string): boolean => {
  const children = urlTree.root.children[PRIMARY_OUTLET];
  const segments = children.segments;

  return segments.filter((segment) => segment.path === path).length > 0;
};

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

/**
 * @param defaultValue Value to default to if value param is `null`, `undefined`, or `NaN`
 * @param value Value to check
 * @returns The value if it is not `null`, `undefined` or `NaN`, otherwise the default value
 */
export const defaultTo = (defaultValue: any) => (value: any) => {
  return value == null || value !== value ? defaultValue : value;
};
