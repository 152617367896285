import { Injectable } from '@angular/core';
import { RollbarService } from '@becksdevteam/rollbar-angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import config from 'app/app.config';
import { FlagsmithFeatureFlagActions } from 'app/store/actions';
import { FlagsmithFeatureFlagEntity } from 'app/store/reducers';
import { selectFlagsmithFeatureFlagsLoaded } from 'app/store/selectors';
import flagsmith from 'flagsmith-es';
import { IFlags } from 'flagsmith-es/types';
import { of, switchMap } from 'rxjs';

@Injectable()
export class FlagsmithFeatureFlagEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private rollbarService: RollbarService
  ) {
    flagsmith.init({
      environmentID: config.flagsmithKey,
      realtime: true,
      enableLogs: false,
      onChange: (_oldFlags, params) => {
        if (params.flagsChanged) {
          this.store.dispatch(FlagsmithFeatureFlagActions.flagsChanged({ flagKeys: params.flagsChanged }));
        }
      },
      onError: (err) => {
        this.rollbarService.log('Flagsmith Error', {
          level: 'error',
          custom: { err }
        });
      }
    });
  }

  flagsmithFeatureFlagsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlagsmithFeatureFlagActions.flagsChanged),
      concatLatestFrom(() => this.store.select(selectFlagsmithFeatureFlagsLoaded)),
      switchMap(([{ flagKeys }, flagsLoaded]) => {
        const flags = flagsmith.getState().flags;
        if (flags) {
          const featureFlagEntities = this.flagsmithFeatureFlagsToEntities(flags);
          if (flagsLoaded) {
            const actions: Action[] = [];

            const changedFlags = featureFlagEntities.filter((flag) => flagKeys.includes(flag.name));
            if (changedFlags.length > 0) {
              actions.push(FlagsmithFeatureFlagActions.upsertFlags({ flags: changedFlags }));
            }

            const removedFlags = flagKeys.filter((flagKey) => !flags[flagKey]);
            if (removedFlags.length > 0) {
              actions.push(FlagsmithFeatureFlagActions.removeFlags({ flagKeys: removedFlags }));
            }

            return actions;
          }

          return of(FlagsmithFeatureFlagActions.addFlags({ flags: featureFlagEntities }));
        }

        return of(FlagsmithFeatureFlagActions.clearFlags());
      })
    )
  );

  private flagsmithFeatureFlagsToEntities(flags: IFlags<string>): FlagsmithFeatureFlagEntity[] {
    return Object.entries(flags).reduce<FlagsmithFeatureFlagEntity[]>((acc, [key, value]) => {
      acc.push({ name: key, ...value });
      return acc;
    }, []);
  }
}
