/** Guards */
import { authenticationGuard, authorizationChildGuard } from 'app/core/auth/guards';

import { Route } from '@angular/router';
import config from 'app/app.config';
import { layoutResolver } from 'app/layout/containers/layout/layout.resolve';
import { flagsmithFeatureFlagsGuard } from 'app/shared/guards/flagsmith-feature-flags.guard';
import { globalGuard } from 'app/shared/guards/global.guard';
import { userAccountStatusGuard } from 'app/shared/guards/user-account-status.guard';
import { userGuard } from 'app/shared/guards/user.guard';

export const APP_ROUTES: Route[] = [
  // redirects from `/app` to `/` for backwards compatibility
  {
    path: 'app',
    redirectTo: ''
  },
  {
    path: 'account-link',
    loadChildren: () => import('./account-link/account-link.routes').then((m) => m.ACCOUNT_LINK_ROUTES)
  },
  {
    path: 'cookieJar',
    canActivate: [authenticationGuard, userGuard, userAccountStatusGuard],
    pathMatch: 'full',
    loadComponent: () => import('./shared/components/cookie-jar/cookie-jar.component').then((m) => m.CookieJarComponent)
  },
  {
    path: '',
    loadComponent: () => import('./layout/containers/layout/layout.component').then((m) => m.LayoutComponent),
    canActivate: [authenticationGuard, userGuard, userAccountStatusGuard, globalGuard, flagsmithFeatureFlagsGuard],
    canActivateChild: [authenticationGuard, authorizationChildGuard],
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        loadComponent: () => import('./shared/components/iframe-wrapper/iframe-wrapper.component').then((m) => m.IframeWrapperComponent),
        data: {
          reactBaseUrl: config.reactClientUrl,
          url: () => `/r`,
          title: 'User Dashboard'
        }
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.routes').then((m) => m.ADMINISTRATION_ROUTES)
      },
      {
        path: 'preferences',
        loadChildren: () => import('./preferences/preferences.routes').then((m) => m.PREFERENCES_ROUTES)
      },
      {
        path: 'dealer/prospects',
        loadChildren: () => import('./dealer/dealer-prospects/dealer-prospects.routes').then((m) => m.DEALER_PROSPECTS_ROUTES)
      },
      {
        path: 'toolbox',
        loadChildren: () => import('./toolbox/toolbox.routes').then((m) => m.TOOLBOX_ROUTES)
      },
      {
        path: 'finance',
        loadChildren: () => import('./finance/finance.routes').then((m) => m.FINANCE_ROUTES)
      },
      {
        path: 'business',
        loadChildren: () => import('./business/business.routes').then((m) => m.BUSINESS_ROUTES)
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.routes').then((m) => m.HELP_ROUTES)
      },
      {
        path: 'tools',
        loadChildren: () => import('./tools/tools.routes').then((m) => m.TOOLS_ROUTES)
      },
      {
        path: 'customerReview',
        loadChildren: () => import('./customer-review/customer-review.routes').then((m) => m.CUSTOMER_REVIEW_ROUTES)
      },
      {
        path: 'salesManagement',
        loadChildren: () => import('./sales-management/sales-management.routes').then((m) => m.SALES_MANAGEMENT_ROUTES)
      },
      {
        path: 'product-visibility-management?type=Excluded',
        loadComponent: () => import('./shared/components/iframe-wrapper/iframe-wrapper.component').then((m) => m.IframeWrapperComponent),
        resolve: {
          internalIframeRemixUrl: () => window.sessionStorage.getItem('x-remix-internal-url')
        },
        data: {
          iframeAngularLink: 'product-visibility-management?type=Excluded',
          reactBaseUrl: config.reactClientUrl,
          url: () => '/r/product-visibility-management?type=Excluded',
          title: 'Product Visibility - Excluded Products'
        }
      },
      {
        path: 'product-visibility-management?type=Included',
        loadComponent: () => import('./shared/components/iframe-wrapper/iframe-wrapper.component').then((m) => m.IframeWrapperComponent),
        resolve: {
          internalIframeRemixUrl: () => window.sessionStorage.getItem('x-remix-internal-url')
        },
        data: {
          iframeAngularLink: 'product-visibility-management?type=Included',
          reactBaseUrl: config.reactClientUrl,
          url: () => '/r/product-visibility-management?type=Included',
          title: 'Product Visibility - Included Products'
        }
      },
      {
        path: 'somethingWrong',
        loadComponent: () => import('./shared/components/something-wrong/something-wrong.component').then((m) => m.SomethingWrongComponent),
        data: {
          title: 'Something Went Wrong'
        }
      },
      // REDIRECTS for Top-Level Modules
      //  - used to be '/prospects', now it lives under '/dealer'
      // test URL (https://local.beckshybrids.com:4200/app/prospects/manageApplication) - redirect to (https://local.beckshybrids.com:4200/app/dealer/prospects/manageApplication)
      {
        path: 'prospects/manageApplication',
        redirectTo: '/dealer/prospects/manageApplication'
      },
      // test URL (https://local.beckshybrids.com:4200/app/prospects/13171) - redirect to (https://local.beckshybrids.com:4200/app/dealer/prospects/13171)
      {
        path: 'prospects/:prospectID',
        redirectTo: '/dealer/prospects/:prospectID',
        pathMatch: 'prefix'
      },
      // test URL (https://local.beckshybrids.com:4200/app/prospects) - redirect to (https://local.beckshybrids.com:4200/app/dealer/prospects)
      {
        path: 'prospects',
        redirectTo: '/dealer/prospects'
      },
      // Business Tier redirects
      {
        path: 'dealer/businessPlan',
        redirectTo: 'business/dealer/businessPlan'
      },
      {
        path: 'dealer/inventory',
        redirectTo: 'business/dealer/inventory'
      },
      {
        path: 'customer',
        redirectTo: 'business/customer'
      },
      // default catch-all redirect
      {
        path: '**',
        redirectTo: '/somethingWrong'
      }
    ],
    data: {
      title: 'BOSSng'
    },
    resolve: {
      layoutResolver
    }
  },
  {
    path: 'errorOccurred',
    loadComponent: () => import('./shared/components/something-wrong/something-wrong.component').then((m) => m.SomethingWrongComponent)
  }
];
