import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Cookie } from 'ng2-cookies';

import config from 'app/app.config';
import { tap } from 'rxjs';

@Component({
  selector: 'bng-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  imports: [RouterLink],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements OnInit {
  constructor(
    private _router: Router,
    private _http: HttpClient
  ) {}

  ngOnInit() {
    // TODO: remove this when we old boss is deprecated
    Cookie.delete(config.authCookieName, '/', '.beckshybrids.com');

    this.iframeLogout();
  }

  iframeLogout() {
    this._http
      .get(`${config.reactClientUrl}/r/iframe-logout`, { withCredentials: true })
      .pipe(
        tap(() => {
          this._router.navigate(['/']);
        })
      )
      .subscribe();
  }
}
