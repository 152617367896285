import { createReducer, on } from '@ngrx/store';

import { IHomeWarehouse, IUser, IUserSecurity, UserAccountStatus } from 'app/shared/models/user.interface';

import { UserActions } from '../actions/user.action';

const defaultUser: IUser = {
  email: '',
  firstName: '',
  lastName: '',
  pictureUrl: '',
  userName: '',
  oktaUserId: ''
};

const defaultSecurity: IUserSecurity = {
  id: 0,
  email: '',
  defaultCustID: 0,
  salesRepDlrID: 0,
  defaultBeckType: '',
  securityObjects: []
};

const defaultWarehouse: IHomeWarehouse = {
  warehouseCustID: 0,
  warehouseID: '',
  description: '',
  msrepl_tran_version: '',
  location: '',
  abbreviation: '',
  latitude: 0,
  longitude: 0,
  homeWarehouseDescription: '',
  alias: '',
  phoneNumber: ''
};

export interface UserState {
  user: IUser;
  security: IUserSecurity;
  accountStatus: UserAccountStatus;
  warehouse: IHomeWarehouse;
  userLoaded: boolean;
  userLoading: boolean;
  userErred: boolean;
  warehouseLoaded: boolean;
  warehouseLoading: boolean;
  warehouseErred: boolean;
}

const initialState: UserState = {
  user: defaultUser,
  security: defaultSecurity,
  accountStatus: 'unknown',
  warehouse: defaultWarehouse,
  userLoaded: false,
  userLoading: false,
  userErred: false,
  warehouseLoaded: false,
  warehouseLoading: false,
  warehouseErred: false
};

export const userStateKey = 'user';
export const userReducer = createReducer(
  initialState,
  on(UserActions.loadUser, (state) => ({
    ...state,
    userLoading: true,
    userLoaded: false,
    userErred: false
  })),
  on(UserActions.loadUserFail, (state) => ({
    ...state,
    userLoaded: false,
    userLoading: false,
    userErred: true
  })),
  on(UserActions.loadUserSuccess, (state, { user, security, accountStatus }) => ({
    ...state,
    userLoaded: true,
    userLoading: false,
    userErred: false,
    user,
    security,
    accountStatus
  })),
  on(UserActions.loadUserHomeWarehouse, (state) => ({
    ...state,
    warehouseLoading: true,
    warehouseLoaded: false,
    warehouseErred: false
  })),
  on(UserActions.loadUserHomeWarehouseFail, (state) => ({
    ...state,
    warehouseLoading: false,
    warehouseLoaded: false,
    warehouseErred: true
  })),
  on(UserActions.loadUserHomeWarehouseSuccess, (state, { warehouse }) => ({
    ...state,
    warehouseLoaded: true,
    warehouseLoading: false,
    warehouseErred: false,
    warehouse
  }))
);
