import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { getUserAccountStatus, getUserLoaded } from 'app/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

export const userAccountStatusGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(getUserLoaded).pipe(
    // only pass when user is authenticated
    filter((userIsLoaded) => userIsLoaded),
    // perform necessary loads
    switchMap(() => checkStore(store, router))
  );
};
const checkStore = (store: Store, router: Router): Observable<boolean> => {
  return store.select(getUserAccountStatus).pipe(
    map((accountStatus) => {
      if (accountStatus === 'active') {
        return true;
      } else if (['no_account', 'unknown'].includes(accountStatus)) {
        router.navigate(['/account-link']);
        return false;
      } else if (['inactive', 'locked'].includes(accountStatus)) {
        router.navigate(['/logout']);
        return false;
      } else if (accountStatus === 'no_access') {
        return false;
      }

      return false;
    }),
    take(1)
  );
};
