import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';

import { AuthService } from '../../auth.service';

@Component({
  selector: 'bng-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss'],
  imports: [NgIf],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginCallbackComponent implements OnInit {
  error = signal<string | null>(null);

  constructor(private _authService: AuthService) {}

  ngOnInit() {
    /**
     * Handles the response from Okta and parses tokens.
     */
    this._authService.handleRedirect().catch((err) => {
      this.error.set(err.toString());
    });
  }

  handleClickHere() {
    this._authService.loginRedirect().catch((err) => {
      this.error.set(err.toString());
    });
  }
}
