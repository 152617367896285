import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RollbarModule } from '@becksdevteam/rollbar-angular';
import { v4 as uuid } from 'uuid';

/** Custom Modules */
import { AuthModule } from './auth/auth.module';

/** Interceptors */
import { BlobErrorHttpInterceptor } from './interceptors/blob-error-http.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

/** Services */
import { CustomRendererService } from './services/custom-renderer.service';
import { LoadActionGuardFactoryService } from './services/load-action-guard-factory.service';
import { StartupService } from './services/startup.service';

import config from 'app/app.config';
import { SocketIoModule } from 'app/core/sockets/socket-io.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import packageInfo from '../../../package.json';
import { GlobalErrorHandler } from './classes/global-error-handler.class';
import { ModuleImportOnceGuard } from './classes/module-import-once-guard.class';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
    AuthModule.forRoot({
      issuer: `${config.okta.externalOrgUrl}/oauth2/default`,
      clientId: config.okta.clientId,
      redirectUri: `${config.bossUrl}/login/callback`,
      postLogoutRedirectUri: `${config.bossUrl}/logout`,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      responseType: ['token', 'id_token'],
      state: uuid(),
      tokenManager: {
        autoRenew: true
      },
      /** @see https://github.com/okta/okta-auth-js/blob/master/docs/autoRenew-notice.md */
      services: {
        autoRenew: false,
        autoRemove: false
      },
      storageManager: {
        token: {
          storageKey: 'bossOktaStorage',
          storageTypes: ['localStorage', 'sessionStorage', 'cookie'],
          useSeparateCookies: true
        },
        cache: {
          storageTypes: ['localStorage', 'sessionStorage', 'cookie']
        },
        transaction: {
          storageTypes: ['cookie']
        }
      },
      pkce: true,
      cookies: {
        sameSite: 'strict'
      },
      devMode: config.name === 'local'
    }),
    InlineSVGModule.forRoot(),
    RollbarModule.forRoot({
      accessToken: config.rollbarAccessToken,
      environment: config.name,
      codeVersion: packageInfo.version,
      autoInstrument: {
        log: false
      },
      payload: {
        environment: config.name,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: packageInfo.version,
            // Have Rollbar guess which frames the error was
            // thrown from when the browser does not provide line
            // and column numbers.
            guess_uncaught_frames: true
          }
        }
      }
    }),
    SocketIoModule.forRoot({
      url: config.socket.url,
      options: {
        forceNew: true,
        transports: ['websocket'],
        path: config.socket.path,
        reconnection: true,
        reconnectionAttempts: 9999,
        timeout: 10000
      }
    })
  ],
  providers: [
    LoadActionGuardFactoryService,
    CustomRendererService,
    StartupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class CoreModule extends ModuleImportOnceGuard {
  public constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}
