import { Injectable } from '@angular/core';
import { RollbarService } from '@becksdevteam/rollbar-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import flagsmith from 'flagsmith-es';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { UserActions } from '../actions/user.action';

import { UserType } from 'app/shared/models/user.interface';
import { UserService } from '../../shared/services/user.service';

@Injectable()
export class UserEffects {
  constructor(
    private _actions$: Actions,
    private _userService: UserService,
    private _rollbarService: RollbarService
  ) {}

  userLoaded$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.loadUser),
      switchMap(() =>
        forkJoin([this._userService.fetchUser(), this._userService.fetchUserAccountStatus()]).pipe(
          switchMap(([user, accountStatus]) =>
            this._userService.fetchUserSecurity(user.id as number).pipe(
              map((security) => {
                return UserActions.loadUserSuccess({
                  user: {
                    ...user,
                    activeCustID: security.defaultCustID ?? 14,
                    activeCustBeckType: security.defaultBeckType,
                    salesRepDlrID: security.salesRepDlrID
                  },
                  security,
                  accountStatus: accountStatus.status
                });
              }),
              catchError((error) => {
                return of(UserActions.loadUserFail({ error }));
              })
            )
          )
        )
      )
    )
  );

  setFlagsmithLoggedInUser$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.loadUserSuccess),
        tap(({ user }) => {
          flagsmith.identify(user.email, {
            email: user.email,
            username: user.userName,
            id: user.id ?? null,
            type: user.type === UserType.AD_INTERNAL ? 'internal' : 'external'
          });
        })
      ),
    { dispatch: false }
  );

  setRollbarLoggedInUser$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserActions.loadUserSuccess),
        tap(({ user }) => {
          this._rollbarService.configure({
            payload: {
              person: {
                id: user.id ?? null,
                username: user.userName,
                email: user.email
              }
            }
          });
        })
      ),
    { dispatch: false }
  );

  triggerLoadUserHomeWarehouse$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.loadUserSuccess),
      map(({ user }) => {
        const isInternal = user.type === UserType.AD_INTERNAL;
        const custID = isInternal ? undefined : user.activeCustID;

        return UserActions.loadUserHomeWarehouse({ custID });
      })
    )
  );

  loadUserHomeWarehouse$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.loadUserHomeWarehouse),
      switchMap(({ custID }) => {
        return this._userService.fetchUserHomeWarehouse(custID).pipe(
          map((warehouse) => {
            return UserActions.loadUserHomeWarehouseSuccess({ warehouse });
          }),
          catchError((error) => {
            return of(UserActions.loadUserHomeWarehouseFail({ error }));
          })
        );
      })
    )
  );
}
