import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuService {
  public showSidebarMenu = new Subject();

  private mobileMenu = new Subject<boolean>();
  private navMenuOpen: boolean = false;

  mobileMenu$ = this.mobileMenu.asObservable();

  constructor() {
    this.mobileMenu.next(this.navMenuOpen);
  }

  toggleMobileMenu() {
    this.navMenuOpen = !this.navMenuOpen;
    this.mobileMenu.next(this.navMenuOpen);
  }
}
